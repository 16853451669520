import Router from 'next/router';
import { useCallback, useMemo, useReducer } from 'react';
import useScrollIntoView from '@/hooks/useScrollIntoView';
import dataLayer from '../../../../../../../../../../../libs/shared/utils/dataLayer';
import isBlackouted from '@/utils/isBlackouted';
import userInteractionEvent from '../../../../../../../../../../../libs/shared/utils/userInteractionEvent';
import useFlyingFrom from '@/libs/v2/hooks/useFlyingFrom';
import { ORIGINS } from '@/constants';
import useSearchQuery from '@/libs/v2/hooks/useSearchQuery';
import { useNewSearchPage } from '@/v2/hooks/featureFlags/searchPage/useNewSearchPage';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';
import {
  handleDateDataLayerEvent,
  handleFlyingFromDatalayerEvent,
  handleOccupantsDataLayerEvent,
  handleTravellingToDatalayerEvent,
} from '@/v2/utils/dataLayer/searchEvents/changeHandlers';
import { searchSubmitEvent } from '@/v2/utils/dataLayer/searchEvents/events';

export const NOT_AVAILABLE_ROUTE_ERROR = 'NOT_AVAILABLE_ROUTE_ERROR';
export const EMPTY_FIELD_ERROR = 'EMPTY_FIELD_ERROR';

const isAvailableRoute = ({ origin, destination }) => {
  if (!origin || !destination) {
    return true;
  }

  return destination.searchConfig.originCodes.includes(origin.code);
};

const validate = (data) => {
  const isFieldEmpty = (key) => !data[key];
  const isRouteAvailable = isAvailableRoute(data);

  return {
    origin: isFieldEmpty('origin') ? EMPTY_FIELD_ERROR : '',
    destination: isFieldEmpty('destination') ? EMPTY_FIELD_ERROR : '',
    departureDate: isFieldEmpty('departureDate') ? EMPTY_FIELD_ERROR : '',
    returnDate: isFieldEmpty('returnDate') ? EMPTY_FIELD_ERROR : '',
    route: !isRouteAvailable ? NOT_AVAILABLE_ROUTE_ERROR : '',
  };
};

const constructInitialStateFromSearchQuery = (searchQuery, destination) => ({
  data: {
    destination,
    origin:
      searchQuery?.originCode &&
      destination?.searchConfig.origins.find(
        (o) => o.code === searchQuery.originCode,
      ),
    departureDate: searchQuery.departureDate,
    returnDate: searchQuery.returnDate,
    occupants: {
      adults: searchQuery.adults ? searchQuery.adults : 2,
      children: searchQuery.children ? searchQuery.children : 0,
      infants: searchQuery.infants ? searchQuery.infants : 0,
    },
  },
  dirty: false,
});

const initialState = {
  data: {
    occupants: { adults: 2, children: 0, infants: 0 },
  },
  dirty: false,
};

const reducer = (state, action) => {
  const data = {
    ...state.data,
    ...action.value,
  };

  switch (action.type) {
    case 'CHANGE_ROUTE': {
      const { origin, destination, departureDate, returnDate } = data;

      const blackoutDates =
        destination && origin && destination.blackoutDates[data.origin.code];

      return {
        ...state,
        data: {
          ...state.data,
          origin,
          destination: action.value.origin ? null : destination, // Reset the destination every time the flying from changes.
          ...(blackoutDates &&
            isBlackouted(blackoutDates, { departureDate, returnDate }) && {
              departureDate: undefined,
              returnDate: undefined,
            }),
        },
      };
    }

    case 'CHANGE_DATE_RANGE': {
      const { departureDate, returnDate } = data;

      return {
        ...state,
        data: {
          ...state.data,
          departureDate,
          returnDate,
        },
      };
    }

    case 'CHANGE_TRAVELLERS': {
      return {
        ...state,
        data: {
          ...state.data,
          occupants: action.value,
        },
      };
    }

    case 'FORM_TOUCHED': {
      return {
        ...state,
        dirty: true,
      };
    }

    default:
      return state;
  }
};

const useSearchForm = (props = {}) => {
  const shouldUseNewEvents = useGA4Events();
  const isNewSearchPageEnabled = useNewSearchPage();

  const { isHomeSearch = false } = props;
  const { searchQuery, setSearchQuery } = useSearchQuery();

  const [state, dispatch] = useReducer(
    reducer,
    isHomeSearch
      ? initialState
      : constructInitialStateFromSearchQuery(searchQuery, props.destination),
  );

  const scrollIntoCheckAvailability = useScrollIntoView('#check-availability');
  const scrollToTitle = useScrollIntoView('#destination-title');

  const { setOriginCode } = useFlyingFrom(ORIGINS.map((o) => o.code));

  const changeRoute = useCallback(
    (value) => {
      if (isHomeSearch) {
        setOriginCode(value?.origin?.code);
      }

      // GA4 event handlers
      if (shouldUseNewEvents) {
        if (value.origin) {
          handleFlyingFromDatalayerEvent(
            state?.data?.origin?.name,
            value?.origin?.name,
          );
        } else {
          handleTravellingToDatalayerEvent(
            state?.data?.destination?.title,
            value?.destination?.title,
          );
        }
      }

      return dispatch({
        type: 'CHANGE_ROUTE',
        value,
      });
    },
    [
      isHomeSearch,
      setOriginCode,
      shouldUseNewEvents,
      state?.data?.destination?.title,
      state?.data?.origin?.name,
    ],
  );

  const changeDateRange = useCallback(
    (value) => {
      //GA4 event handler
      if (shouldUseNewEvents) {
        handleDateDataLayerEvent(state.data, value);
      }

      return dispatch({
        type: 'CHANGE_DATE_RANGE',
        value,
      });
    },
    [shouldUseNewEvents, state.data],
  );

  const changeTravellers = useCallback(
    (value) => {
      //GA4 event handler
      if (shouldUseNewEvents) {
        handleOccupantsDataLayerEvent(state?.data?.occupants, value);
      }
      return dispatch({
        type: 'CHANGE_TRAVELLERS',
        value,
      });
    },
    [shouldUseNewEvents, state?.data?.occupants],
  );

  const error = useMemo(() => validate(state.data), [state]);

  const valid = useMemo(() => {
    const result = validate(state.data);
    return Object.values(result).every((v) => !v);
  }, [state]);

  const submit = () => {
    dispatch({
      type: 'FORM_TOUCHED',
    });

    if (!valid) {
      return;
    }

    if (shouldUseNewEvents) {
      searchSubmitEvent();
    }

    const { origin, destination, occupants, departureDate, returnDate } =
      state.data;

    // If user is on the homepage or updating the destination on a search page,
    // re-route them
    if (isHomeSearch || props.destination?.name !== destination?.name) {
      dataLayer.push(
        userInteractionEvent(
          'Supported Search',
          `${origin.name} - ${destination.title}`,
          'Home Page Search',
        ),
      );

      Router.push({
        pathname: isNewSearchPageEnabled
          ? `/search/${destination.name}`
          : `/${destination.name}`,
        query: {
          originCode: origin.code,
          departureDate,
          returnDate,
          ...occupants,
        },
      }).then(() => {
        // If going from home page to destination => scroll to check availability
        if (!isNewSearchPageEnabled) {
          scrollIntoCheckAvailability();
          // else, if new search NOT from home page => scroll to title
        } else if (!isHomeSearch) {
          scrollToTitle({ behavior: 'smooth' });
        }
      });
    } else {
      setSearchQuery(
        {
          originCode: origin.code,
          destinationCode: destination.searchConfig.destinationCode,
          departureDate,
          returnDate,
          ...occupants,
        },
        true,
      );
      // If on new search page => scroll to title
      if (!isHomeSearch) {
        scrollToTitle({ behavior: 'smooth' });
      }
    }
  };

  const { data } = state;

  return {
    data,
    error,
    valid,
    changeRoute,
    changeDateRange,
    changeTravellers,
    submit,
  };
};

export default useSearchForm;
